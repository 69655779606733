import React, { ReactNode, useState } from 'react';
import { JsonPropsType } from '../../component-loader';
import { AsyncTable, AsyncTableProps } from './AsyncTable';
import { formatDateStringToDisplay, formatToLocaleNumberString } from '../../utils/format';
import { FundDividend } from '../../types/funds';

type FundDividendsTableProps = AsyncTableProps<FundDividend>;

export function FundDividendsTable(props: JsonPropsType<FundDividendsTableProps>) {
  const [fundDividends, setFundDividends] = useState<FundDividend[]>([]);

  if (!props.jsonObject) return <div />;

  const { url, columns } = props.jsonObject;
  const onSuccess = (data: FundDividend[]) => setFundDividends(data);

  const rows: ReactNode[] = fundDividends.map((fundDividend) => (
    <tr key={fundDividend.id}>
      <td>{formatDateStringToDisplay(fundDividend.date)}</td>
      <td>
        {formatToLocaleNumberString({
          value: fundDividend.total,
          currencyFormat: 'clp',
          round: true,
        })}
      </td>
      <td>
        {formatToLocaleNumberString({
          value: fundDividend.my_dividend,
          currencyFormat: 'clp',
          round: true,
        })}
      </td>
      <td>{fundDividend.fund_series}</td>
    </tr>
  ));

  return (
    <AsyncTable<FundDividend>
      jsonObject={{
        url,
        onSuccess,
        columns,
        rows,
      }}
    />
  );
}
