import React from 'react';
import { JsonPropsType } from '../../component-loader';
import { CapitalRaise } from '../../types/funds';
import { LoadingOverlay } from '../overlay';

function CapitalRaiseItem({ capitalRaise, index }: { capitalRaise: CapitalRaise, index: number }) {
  const COLORS = ['#CFE2FF', '#031D6F', '#292FA1'];
  const percentage = capitalRaise.raised
    ? Math.round((capitalRaise.paid * 100) / capitalRaise.raised)
    : 0;
  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <p className="mb-0 text-gray-600 fw-bold">{capitalRaise.name}</p>
        <p className="mb-0" style={{ color: '#808191' }}>{`${percentage}%`}</p>
      </div>
      <div className="progress" role="progressbar" aria-valuenow={percentage} aria-valuemin={0} aria-valuemax={100} style={{ height: '5px' }}>
        <div className="progress-bar" style={{ width: `${percentage}%`, backgroundColor: COLORS[index % COLORS.length] }} />
      </div>
    </div>
  );
}

export interface DashboardCapitalCallsProps {
  data: CapitalRaise[];
  subtitle: string;
  fundUrl: string;
  loading: boolean;
}

export function DashboardCapitalCalls({ jsonObject }: JsonPropsType<DashboardCapitalCallsProps>) {
  if (!jsonObject) return <div />;

  const {
    data,
    subtitle,
    fundUrl,
    loading,
  } = jsonObject;
  return (
    <div className="card overflow-hidden">
      <div className="card-body position-relative">
        <LoadingOverlay show={loading} />
        <p className="h6 text-gray-800 mb-2">Levantamientos de capital</p>
        <p className="text-gray-600">{subtitle}</p>
        <div className="d-flex flex-column gap-3">
          {data.map(
            (capitalRaise, index) => (
              <CapitalRaiseItem capitalRaise={capitalRaise} index={index} key={capitalRaise.name} />
            )
          )}
        </div>
        <a href={fundUrl} className="btn btn-outline-primary mt-4 w-100">
          Ver más información
          <span className="icon material-icons-two-tone ms-auto" aria-hidden="true">
            keyboard_arrow_right
          </span>
        </a>
      </div>
    </div>
  );
}
