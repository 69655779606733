type GenerateButtonParams = {
  label: string,
  onClick: (e: MouseEvent) => void;
};

const generateButton = ({
  label,
  onClick,
}: GenerateButtonParams) => {
  const button = document.createElement('a');
  button.href = '/';
  button.textContent = label;
  button.classList.add('fw-bold', 'd-block', 'mw-maxcontent');
  button.addEventListener('click', onClick);
  return button;
};

const getTruncateDataFromElement = (el: HTMLElement) => {
  const { innerHTML, dataset: { textTruncate } } = el;
  const textMaxLength = Number(textTruncate);
  const textWithoutBr = innerHTML.replaceAll('<br>', ' ');
  const shouldTruncate = Number.isNaN(textMaxLength)
    ? false
    : textWithoutBr.length > textMaxLength;
  return {
    innerHTML,
    textMaxLength: Number.isNaN(textMaxLength) ? null : textMaxLength,
    shouldTruncate,
    textWithoutBr,
  };
};

const truncateText = (text: string, length: number) => `${text.substring(0, length)}...`;

/**
 * Simulates text truncation in an element. Uses innerHTML
 * instead of textContent to preserve <br> whitespaces.
 */
const handleTextTruncationInElement = (element: HTMLElement, originalText = '') => {
  const truncateData = getTruncateDataFromElement(element);
  const originalTextContent = originalText || truncateData.innerHTML || '';

  if (!truncateData.innerHTML || !truncateData.textMaxLength) return;

  if (truncateData.shouldTruncate) {
    // eslint-disable-next-line no-param-reassign
    element.innerHTML = truncateText(truncateData.textWithoutBr, truncateData.textMaxLength);
    // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
    const viewMoreButton = generateViewMoreButton(element, originalTextContent);
    element.appendChild(viewMoreButton);
  }
};

const generateViewLessButton = (parentElement: HTMLElement, originalText: string) => {
  const viewLessButton = generateButton({
    label: 'Ver menos',
    onClick: (e) => {
      e.preventDefault();
      handleTextTruncationInElement(parentElement, originalText);
    },
  });
  return viewLessButton;
};

const generateViewMoreButton = (parentElement: HTMLElement, textToShow: string) => {
  const viewMoreButton = generateButton({
    label: 'Ver más',
    onClick: (e) => {
      e.preventDefault();
      // eslint-disable-next-line no-param-reassign
      parentElement.innerHTML = textToShow;
      const viewLessButton = generateViewLessButton(parentElement, textToShow);
      parentElement.appendChild(viewLessButton);
    },
  });

  return viewMoreButton;
};

window.addEventListener('DOMContentLoaded', () => {
  const textsToTruncate = document.querySelectorAll<HTMLElement>('[data-text-truncate]');
  textsToTruncate.forEach((el) => {
    handleTextTruncationInElement(el);
  });
});
