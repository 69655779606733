import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { initChoices } from '../../vendors/choices';

export type SelectItem = {
  value: string | number,
  label: string
};

interface SelectProps {
  items: SelectItem[];
  onChange: (item: SelectItem) => void;
}

export function Select({ items, onChange }: SelectProps) {
  const [selectedItem, setSelectedItem] = useState<SelectItem | null>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (selectedItem) onChange(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (items.length && !selectedItem) setSelectedItem(items[0]);
  }, [items]);

  useEffect(() => {
    if (selectRef.current) initChoices(selectRef.current);
  }, [selectRef.current]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const selected = items.find((item) => item.value.toString() === value);
    if (selected) setSelectedItem(selected);
  };

  return (
    <select
      value={selectedItem?.value}
      onChange={handleChange}
      ref={selectRef}
    >
      {items?.map((item) => (
        <option
          key={item.value}
          value={item.value}
        >
          {item.label}
        </option>
      ))}
    </select>
  );
}
