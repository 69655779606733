import React, { PropsWithChildren, useEffect, useState } from 'react';
import { JsonPropsType } from '../../component-loader';
import { ActiveFund, FundStats } from '../../types/funds';
import { ResponseData } from '../../types/http';
import { Select, SelectItem } from '../form';
import { DashboardGraphToggler } from './DashboardGraphToggler';
import { DashboardCapitalCalls } from './DashboardCapitalCalls';
import { LoadingOverlay } from '../overlay';
import { formatGenericFetchResponse } from '../../utils/http';

interface ErrorCardProps extends PropsWithChildren {
  loading: boolean;
}

function ErrorCard({ children, loading }: ErrorCardProps) {
  return (
    <div className="card overflow-hidden" style={{ height: children ? 'unset' : '200px' }}>
      <LoadingOverlay show={loading} />
      <div className="card-body" style={{ height: '200px' }}>
        {children}
      </div>
    </div>
  );
}
export interface DashboardGraphSectionProps {
  url: string;
  summaryTitle: string;
  summarySubtitle: string;
}

export function DashboardGraphSection(props: JsonPropsType<DashboardGraphSectionProps>) {
  const [funds, setFunds] = useState<ActiveFund[] | null>(null);
  const [selectedFund, setSelectedFund] = useState<ActiveFund | null>(null);
  const [fundStats, setFundStats] = useState<FundStats | null>(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const { jsonObject } = props;
  const fetchStats = async (fundId: number) => {
    setLoading(true);
    fetch(`/api/v1/investors/funds/${fundId}/stats/`)
      .then((res) => res.json())
      .then((data: FundStats) => {
        setShowError(false);
        setFundStats(data);
      })
      .catch(() => setShowError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    void (async () => {
      if (!selectedFund) return;
      await fetchStats(selectedFund.id);
    })();
  }, [selectedFund]);

  useEffect(() => {
    if (jsonObject?.url) {
      fetch(jsonObject.url)
        .then((res) => res.json())
        .then((data: ResponseData<ActiveFund>) => {
          const formattedData = formatGenericFetchResponse(data);
          setFunds(formattedData);
        })
        .catch(() => setShowError(true));
    }
  }, [jsonObject?.url]);

  if (!jsonObject) return <div />;

  const handleFundChange = (item: SelectItem) => {
    const selected = funds?.find((fund) => fund.id === Number(item.value));
    if (selected) setSelectedFund(selected);
  };

  const { summarySubtitle, summaryTitle } = jsonObject;

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-8">
          <h2 className="h5 mb-2 text-dark mb-2">{summaryTitle}</h2>
          <p>{summarySubtitle}</p>
        </div>
        <div className="col-md-4">
          {funds?.length && (
            <>
              <p className="text-secondary">Seleccionar fondo</p>
              <Select
                items={funds.map((fund) => ({ label: fund.name, value: fund.id }))}
                onChange={handleFundChange}
              />
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mb-6 mb-md-0">
          {showError && (
            <ErrorCard loading={loading}>
              <p className="mb-0">Error al buscar la información. Intente nuevamente.</p>
              <button
                type="button"
                className="btn btn-link px-0"
                onClick={() => {
                  if (!selectedFund) return;
                  void fetchStats(selectedFund.id);
                }}
              >
                Reintentar
              </button>
            </ErrorCard>
          )}
          {selectedFund && fundStats && !showError && (
            <DashboardGraphToggler
              data={fundStats.promise_statuses}
              subtitle={selectedFund.name}
              fundId={selectedFund.id}
              loading={loading}
            />
          )}
        </div>
        <div className="col-md-4">
          {showError && (
            <ErrorCard loading={loading} />
          )}
          {selectedFund && fundStats && !showError && (
            <DashboardCapitalCalls
              jsonObject={{
                data: fundStats.capital_raises,
                subtitle: selectedFund.name,
                fundUrl: selectedFund.url,
                loading,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
