import React from 'react';
import { JsonPropsType } from '../../component-loader';
import { AsyncTablePaginationButton } from './AsyncTablePaginationButton';
import { Pagination } from '../../types/http';

export type PageDirection = 'next' | 'previous' | 'first' | 'last';

export interface AsyncTablePaginationProps extends Omit<Pagination, 'last' | 'first'> {
  onChangePageClick: (pageDirection: PageDirection) => void;
}

export function AsyncTablePagination({ jsonObject }: JsonPropsType<AsyncTablePaginationProps>) {
  if (!jsonObject) return <div />;

  const {
    onChangePageClick,
    previous,
    next,
    count,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    index_end,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    index_start,
  } = jsonObject;

  const hasPrevious = Boolean(previous);
  const hasNext = Boolean(next);

  const handlePageClick = (pageToGo: PageDirection) => {
    onChangePageClick(pageToGo);
  };

  return (hasPrevious || hasNext) ? (
    <div className="d-flex justify-content-end align-items-center gap-2">
      <p className="fw-medium mb-0">{`${index_start}-${index_end} de ${count}`}</p>
      <nav aria-label="Pagination">
        <ol className="d-inline-flex list-unstyled mb-0">
          <AsyncTablePaginationButton
            jsonObject={{
              ariaLabel: 'Last page',
              disabled: !hasPrevious,
              icon: 'first_page',
              onClick: () => handlePageClick('first'),
            }}
          />
          <AsyncTablePaginationButton
            jsonObject={{
              ariaLabel: 'Previous page',
              disabled: !hasPrevious,
              icon: 'keyboard_arrow_left',
              onClick: () => handlePageClick('previous'),
            }}
          />
          <AsyncTablePaginationButton
            jsonObject={{
              ariaLabel: 'Next page',
              disabled: !hasNext,
              icon: 'keyboard_arrow_right',
              onClick: () => handlePageClick('next'),
            }}
          />
          <AsyncTablePaginationButton
            jsonObject={{
              ariaLabel: 'Last page',
              disabled: !hasNext,
              icon: 'last_page',
              onClick: () => handlePageClick('last'),
            }}
          />
        </ol>
      </nav>
    </div>
  ) : <div />;
}
