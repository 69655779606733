import { ErrorResponse, ResponseData, ResultWithPagination } from '../types/http';

export const formatGenericFetchResponse = <T>(data: ResponseData<T>) => {
  if (Object.hasOwn(data, 'detail')) {
    throw new Error((data as ErrorResponse).detail);
  } else if (Object.hasOwn(data, 'results')) {
    const dataWithPagination = data as ResultWithPagination<T>;
    return dataWithPagination.results;
  } else {
    return data as T[];
  }
};
