import React, { ReactNode, useEffect, useState } from 'react';
import { JsonPropsType } from '../../component-loader';
import { AsyncTableHead } from './AsyncTableHead';
import { AsyncTablePagination, PageDirection } from './AsyncTablePagination';
import { ErrorResponse, ResponseData, ResultWithPagination } from '../../types/http';
import { LoadingOverlay } from '../overlay';

export type AsyncTableColumn = {
  text: string;
  sortLabel?: string;
  classes?: string;
};

export interface AsyncTableProps<Data> {
  url: string,
  onSuccess: (data: Data[]) => void;
  columns: AsyncTableColumn[];
  rows: ReactNode[];
}

export function AsyncTable<Data>(props: JsonPropsType<AsyncTableProps<Data>>) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [sortString, setSortString] = useState('');
  const [response, setResponse] = useState<ResultWithPagination<Data> | null>(null);
  const { jsonObject } = props;

  useEffect(() => {
    if (response && jsonObject) jsonObject.onSuccess(response.results);
  }, [response]);

  const generateValidUrl = (url: string) => {
    let parsedUrl;
    try {
      parsedUrl = new URL(url);
    } catch (e) {
      parsedUrl = new URL(`${window.location.origin}${url}`);
    }
    return parsedUrl;
  };

  const fetchData = (controlledUrl = '') => {
    if (!jsonObject) return;
    const { onSuccess, url } = jsonObject;
    if (!url) return;
    const urlToFetch = generateValidUrl(controlledUrl || url);
    urlToFetch.searchParams.set('o', sortString);

    setLoading(true);
    setShowError(false);

    fetch(urlToFetch.toString())
      .then((res) => res.json())
      .then((data: ResponseData<Data>) => {
        if (Object.hasOwn(data, 'detail')) {
          throw new Error((data as ErrorResponse).detail);
        } else if (Object.hasOwn(data, 'results')) {
          const dataWithPagination = data as ResultWithPagination<Data>;
          setResponse(dataWithPagination);
        } else {
          onSuccess(data as Data[]);
        }
      })
      .catch((err) => {
        console.error(err);
        setShowError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!jsonObject) return;
    fetchData();
    // Force the fetch each time a part of the url changes
  }, [jsonObject?.url, sortString]);

  if (!jsonObject) return <div />;

  const { rows, columns } = jsonObject;

  const handleSort = (sortParam: string) => {
    setSortString(sortParam);
  };

  const onPageButtonClick = (direction: PageDirection) => {
    if (!response) return;
    const newUrl = response[direction];
    if (!newUrl) return;
    fetchData(newUrl);
  };

  return (
    <>
      <div className="table-responsive position-relative">
        <LoadingOverlay show={loading} />
        <table className="table mb-0 mb-3">
          <thead>
            <tr>
              {columns.map((column) => (
                <AsyncTableHead
                  key={column.sortLabel}
                  jsonObject={{
                    text: column.text,
                    sortLabel: column.sortLabel,
                    onSort: handleSort,
                    isSorting: column.sortLabel ? sortString.includes(column.sortLabel) : false,
                    classes: column.classes,
                  }}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {showError ? (
              <tr>
                <td colSpan={columns.length}>
                  Error al buscar la información. Intente nuevamente
                  <button type="button" className="btn btn-link" onClick={() => fetchData()}>Reintentar</button>
                </td>
              </tr>
            ) : rows.map((row) => row)}
          </tbody>
        </table>
      </div>
      {response && (
        <AsyncTablePagination
          jsonObject={{
            count: response.count,
            next: response.next,
            previous: response.previous,
            index_end: response.index_end,
            index_start: response.index_start,
            onChangePageClick: onPageButtonClick,
          }}
        />
      )}
    </>
  );
}
