import React from 'react';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
} from 'recharts';
import { JsonPropsType } from '../../component-loader';
import { PromiseStatuses } from '../../types/funds';

const generateParsedData = (data: PromiseStatuses) => {
  const promisedData = data.find((d) => d.name === 'promised');
  const paidData = data.find((d) => d.name === 'paid');
  const result = {
    promised: {
      name: promisedData?.name || 'promised',
      color: '#031D6F',
      percentage: '0%',
      value: promisedData?.value,
    },
    paid: {
      name: paidData?.name || 'paid',
      color: '#7577C2',
      percentage: '0%',
      value: paidData?.value,
    },
  };

  const { paid: { value: paidValue } } = result;
  const { promised: { value: promisedValue } } = result;

  // Use loose equality to catch only null and undefined values but accept zero
  if (promisedValue == null || paidValue == null) return result;

  const total = promisedValue + paidValue;
  if (total === 0) return result;

  result.paid.percentage = `${Math.round((paidValue * 100) / total)}%`;
  result.promised.percentage = `${Math.round((promisedValue * 100) / total)}%`;
  return result;
};

export interface DashboardGraphProps {
  data: PromiseStatuses;
}

export function DashboardGraph(props: JsonPropsType<DashboardGraphProps>) {
  const { jsonObject } = props;

  if (!jsonObject) return <div />;

  const { data } = jsonObject;
  const parsedData = generateParsedData(data);
  const parsedDataInRenderOrder = [parsedData.paid, parsedData.promised];

  return (
    <div className="row">
      <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end mb-2 mb-lg-0">
        <div style={{ height: '240px', width: '240px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                startAngle={90}
                endAngle={450}
                data={parsedDataInRenderOrder}
                innerRadius={70}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
                // To remove the default gap between sectors
                stroke=""
              >
                {parsedDataInRenderOrder.map((entry) => (
                  <Cell key={`cell-${entry.name}`} fill={parsedData[entry.name].color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
        <div className="d-flex">
          <span
            className="d-inline-block rounded-circle me-2"
            style={{
              width: '1rem',
              height: '1rem',
              backgroundColor: parsedData.promised.color,
              marginTop: '6px',
            }}
          />
          <div className="d-flex flex-column">
            <span className="fw-bold">Promesado</span>
            <span>{parsedData.promised.percentage}</span>
          </div>
        </div>
        <div className="d-flex">
          <span
            className="d-inline-block rounded-circle me-2"
            style={{
              width: '1rem',
              height: '1rem',
              backgroundColor: parsedData.paid.color,
              marginTop: '6px',
            }}
          />
          <div className="d-flex flex-column">
            <span className="fw-bold">Aportado</span>
            <span>{parsedData.paid.percentage}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
