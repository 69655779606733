import React, { ReactNode, useState } from 'react';
import { JsonPropsType } from '../../component-loader';
import { AsyncTable, AsyncTableProps } from './AsyncTable';
import { formatDateStringToDisplay, formatToLocaleNumberString } from '../../utils/format';
import { AsyncTableActionCell } from './AsyncTableActionCell';
import { FundCapitalCall, FundCapitalCallStatus } from '../../types/funds';

type FundCapitalCallStatusBadgeData = {
  className: string,
  label: string,
};

export type FundCapitalCallsTableProps = AsyncTableProps<FundCapitalCall>;

export function FundCapitalCallsTable(props: JsonPropsType<FundCapitalCallsTableProps>) {
  const [fundCapitalCalls, setFundCapitalCalls] = useState<FundCapitalCall[]>([]);
  if (!props.jsonObject) return <div />;

  const { url, columns } = props.jsonObject;
  const onSuccess = (data: FundCapitalCall[]) => setFundCapitalCalls(data);

  const getStatusBadge = (status: FundCapitalCallStatus) => {
    const statusData: Record<FundCapitalCallStatus, FundCapitalCallStatusBadgeData> = {
      AWAITING_APPROVAL: { className: 'bg-gray-200 text-body', label: 'Solicitado' },
      AWAITING_PAYMENT: { className: 'text-bg-warning', label: 'Esperando pago' },
      AWAITING_SIGNATURE: { className: 'text-bg-warning', label: 'Esperando firma' },
      AWAITING_TO_BE_SENT: { className: 'text-bg-warning', label: 'Esperando envío' },
      AWAITING_VOUCHER_APPROVAL: { className: 'text-bg-warning', label: 'Esperando aprobación de comprobante' },
      AWAITING_VOUCHER_SIGNATURE: { className: 'text-bg-warning', label: 'Esperando firma de comprobante' },
      AWAITING_VOUCHER_TO_BE_SENT: { className: 'text-bg-warning', label: 'Esperando envío de comprobante' },
      COMPLETED: { className: 'text-bg-success', label: 'Completado' },
      SENT_WITH_UNKWOWN_UF: { className: 'text-bg-warning', label: 'Enviado con UF desconocido' },
    };
    return (
      <span
        className={`badge d-block fw-medium fs-base text-wrap ${statusData[status].className}`}
      >
        {statusData[status].label}
      </span>
    );
  };

  const rows: ReactNode[] = fundCapitalCalls.map((fundCapitalCall) => (
    <tr key={fundCapitalCall.id}>
      <td>{fundCapitalCall.series}</td>
      <td>{formatDateStringToDisplay(fundCapitalCall.date_of_request)}</td>
      <td>
        {formatToLocaleNumberString({
          value: fundCapitalCall.amount_requested,
          currencyFormat: 'uf',
          round: true,
        })}
      </td>
      <td>{getStatusBadge(fundCapitalCall.status)}</td>
      <AsyncTableActionCell jsonObject={{ url: `${fundCapitalCall.url}` }} />
    </tr>
  ));

  return (
    <AsyncTable<FundCapitalCall>
      jsonObject={{
        url,
        onSuccess,
        columns,
        rows,
      }}
    />
  );
}
