import React, {
  useEffect,
  useState,
} from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import dayjs from 'dayjs';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { JsonPropsType } from '../../component-loader';
import { QuoteGraphTooltipPayload, QuoteValueGraphData, QuoteValueGraphDataWithYear } from '../../types/quoteValue';
import { Select, SelectItem } from '../form';
import { LoadingOverlay } from '../overlay';
import { ResponseData } from '../../types/http';
import { formatGenericFetchResponse } from '../../utils/http';
import { formatDateStringToDisplay, formatToLocaleNumberString } from '../../utils/format';

export interface QuoteValueGraphProps {
  url: string
}

function CustomTooltip({ active, payload }: TooltipProps<ValueType, NameType>) {
  if (active && payload?.length) {
    const data = payload[0].payload as QuoteGraphTooltipPayload;
    return (
      <div>
        <p className="mb-1" style={{ color: '#72767C' }}>{formatDateStringToDisplay(data.date)}</p>
        <p className="mb-0 h6" style={{ color: '#242731' }}>
          {formatToLocaleNumberString({ value: data.price, currencyFormat: 'clp' })}
        </p>
      </div>
    );
  }

  return null;
}

export function QuoteValueGraph(props: JsonPropsType<QuoteValueGraphProps>) {
  const [quoteValues, setQuoteValues] = useState<QuoteValueGraphData[] | null>(null);
  const [selectedQuoteValue, setSelectedQuoteValue] = useState<
  QuoteValueGraphDataWithYear | null
  >(null);

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const { jsonObject } = props;
  const graphHeight = '342px';

  const fetchQuotes = async (urlToFetch: string) => {
    setLoading(true);
    fetch(urlToFetch)
      .then((res) => res.json())
      .then((data: ResponseData<QuoteValueGraphData>) => {
        setShowError(false);
        const formattedData = formatGenericFetchResponse(data);
        setQuoteValues(formattedData);
      })
      .catch(() => setShowError(true))
      .finally(() => setLoading(false));
  };

  /**
   * Generate data needed to render quotes graph
   * @param {QuoteValueGraphData} selectedQuote
   * @returns {QuoteValueGraphDataWithYear}
   */
  const generateSelectedQuoteData = (
    selectedQuote: QuoteValueGraphData
  ): QuoteValueGraphDataWithYear => ({
    ...selectedQuote,
    prices: selectedQuote.prices.map((price) => ({
      ...price,
      year: dayjs(price.date).year(),
    })),
  });

  useEffect(() => {
    void (async () => {
      if (!jsonObject) return;
      const { url } = jsonObject;
      if (url) {
        await fetchQuotes(url);
      }
    })();
  }, [jsonObject?.url]);

  const handleValueChange = (item: SelectItem) => {
    const selected = quoteValues?.find(
      (quoteValue) => quoteValue.fund_series.id === Number(item.value)
    );
    if (selected) {
      setSelectedQuoteValue(generateSelectedQuoteData(selected));
    }
  };

  if (!jsonObject) return <div />;

  return (
    <div className="card overflow-hidden">
      <div className="card-body position-relative">
        <LoadingOverlay show={loading} />
        {showError
          ? (
            <div style={{ height: graphHeight }}>
              <p className="mb-0">Error al buscar la información. Intente nuevamente.</p>
              <button
                type="button"
                className="btn btn-link px-0"
                onClick={() => {
                  if (!jsonObject) return;
                  void fetchQuotes(jsonObject.url);
                }}
              >
                Reintentar
              </button>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between align-item-center mb-2 pb-1">
                <p className="h6 text-gray-800 mb-0">Valor cuota</p>
                {quoteValues?.length && (
                  <Select
                    items={
                      quoteValues
                        .map((qv) => ({ label: qv.fund_series.name, value: qv.fund_series.id }))
                    }
                    onChange={handleValueChange}
                  />
                )}
              </div>
              {selectedQuoteValue?.fund_series && <p className="text-secondary">{selectedQuoteValue.fund_series.name}</p>}
              <div style={{ height: graphHeight }}>
                {selectedQuoteValue?.prices && (
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={500}
                      height={400}
                      data={
                        selectedQuoteValue.prices.map((p) => ({ ...p, price: Number(p.price) }))
                      }
                    >
                      <CartesianGrid strokeDasharray="0" horizontal={false} />
                      <XAxis
                        dataKey="year"
                        axisLine={false}
                        tickLine={false}
                        ticks={
                          Array.from(new Set(selectedQuoteValue.prices.map(p => p.year)))
                        }
                      />
                      <YAxis hide />
                      <Tooltip content={<CustomTooltip />} />
                      <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#CFE2FF" stopOpacity={1} />
                          <stop offset="100%" stopColor="#CFE2FF00" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                      <Area type="linear" dataKey="price" stroke="#031D6F" strokeWidth="3" fill="url(#colorUv)" />
                    </AreaChart>
                  </ResponsiveContainer>
                )}
              </div>
            </>
          )}
      </div>
    </div>
  );
}
