import { getChoicesReferenceById } from '../vendors/choices';

type FormField = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const resetSelectField = (field: HTMLSelectElement) => {
  const choicesRef = getChoicesReferenceById(field.id);
  if (choicesRef && choicesRef.choicesRef) {
    choicesRef.choicesRef.setChoiceByValue('');
  } else {
    // eslint-disable-next-line no-param-reassign
    field.value = '';
  }
};

const clearField = (field: FormField) => {
  const isCheckboxOrRadio = field.tagName.toLocaleLowerCase() === 'input' && (field.type === 'checkbox' || field.type === 'radio');

  if (field.tagName.toLocaleLowerCase() === 'select') {
    resetSelectField(field as HTMLSelectElement);
  } else if (isCheckboxOrRadio) {
    field.removeAttribute('checked');
  } else {
    // eslint-disable-next-line no-param-reassign
    field.value = '';
  }
};

const resetForm = (form: HTMLFormElement) => {
  const fields = Array.from(form.elements).filter((control: Element) => {
    if (control.getAttribute('type') === 'hidden') return false;

    const controlType = control.tagName.toLowerCase();
    return ['input', 'select', 'textarea'].includes(controlType);
  }) as FormField[];

  fields.forEach(clearField);
};

window.addEventListener('DOMContentLoaded', () => {
  const resetButtons = document.querySelectorAll<HTMLButtonElement>('.button-reset-form');
  resetButtons.forEach((btn) => btn.addEventListener('click', () => {
    if (btn.form) {
      resetForm(btn.form);
    } else {
      console.error('The reset form button is not associated with a form.');
    }
  }));
});
