import React, { ReactNode, useState } from 'react';
import dayjs from 'dayjs';
import { JsonPropsType } from '../../component-loader';
import { AsyncTable, AsyncTableProps } from './AsyncTable';
import { formatDateStringToDisplay, formatToLocaleNumberString } from '../../utils/format';
import { FundPromise } from '../../types/funds';

export type FundPromisesTableProps = AsyncTableProps<FundPromise>;

export function FundPromisesTable(props: JsonPropsType<FundPromisesTableProps>) {
  const [fundPromises, setFundPromises] = useState<FundPromise[]>([]);
  if (!props.jsonObject) return <div />;

  const { url, columns } = props.jsonObject;
  const onSuccess = (data: FundPromise[]) => setFundPromises(data);
  const rows: ReactNode[] = fundPromises.map((fundPromise) => (
    <tr key={`${fundPromise.series}-${fundPromise.promise_date}-${fundPromise.promised_amount}`}>
      <td>{fundPromise.series}</td>
      <td>{formatDateStringToDisplay(fundPromise.promise_date)}</td>
      <td>
        {formatToLocaleNumberString({
          value: fundPromise.amount_raised,
          currencyFormat: 'uf',
          round: true,
        })}
      </td>
      <td>
        {formatToLocaleNumberString({
          value: fundPromise.promised_amount,
          currencyFormat: 'uf',
          round: true,
        })}
      </td>
      <td>{fundPromise.grace_days}</td>
    </tr>
  ));

  return (
    <AsyncTable<FundPromise>
      jsonObject={{
        url,
        onSuccess,
        columns,
        rows,
      }}
    />
  );
}
