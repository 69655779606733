function initPasswordVisibilityToggler(button: HTMLButtonElement) {
  button.addEventListener('click', () => {
    const passwordInputId = button.getAttribute('aria-controls');
    const passwordInput = document.getElementById(passwordInputId || '') as HTMLInputElement;

    if (!passwordInput) {
      console.error('The toggle password visibility button has no password input near.');
      return;
    }

    const passwordVisibilityOn = passwordInput.type === 'text';

    passwordInput.setAttribute('type', passwordVisibilityOn ? 'password' : 'text');
    button.setAttribute('aria-expanded', passwordVisibilityOn ? 'false' : 'true');
  });
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  const togglePasswordVisibilityButtons = document.querySelectorAll<HTMLButtonElement>('.btn-password');
  togglePasswordVisibilityButtons.forEach(initPasswordVisibilityToggler);
});
