export const eventDebounce = (
  func: (event: Event) => void,
  delay = 300
): ((event: Event) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (e: Event): void => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.call(this, e);
    }, delay);
  };
};
