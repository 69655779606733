import React from 'react';
import { JsonPropsType } from '../../component-loader';

export interface AsyncTableActionCellProps {
  url: string
}

export function AsyncTableActionCell(props: JsonPropsType<AsyncTableActionCellProps>) {
  const { jsonObject } = props;

  return (
    <td className="text-end">
      <a href={jsonObject?.url || ''} className="btn btn-table">
        <span className="icon material-icons-two-tone" aria-hidden="true">
          keyboard_arrow_right
        </span>
      </a>
    </td>
  );
}
