// Importing Bootstrap forces the load of the type definition for the window.bootstrap global.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Bootstrap from 'bootstrap';

// Styles
import '../scss/main.scss';

// Vendors
import './vendors/choices';
import Alert from 'bootstrap/js/dist/alert';
import Collapse from 'bootstrap/js/dist/collapse';
import Tooltip from 'bootstrap/js/dist/tooltip';
import Dropdown from 'bootstrap/js/dist/dropdown';

// Behaviors
import './behaviors/input-rut';
import './behaviors/regions';
import './behaviors/button-reset-form';
import './behaviors/toggle-password-visibility';
import './behaviors/text-truncate';
import './behaviors/certificate-download-form';

// Utils
import { App } from './utils/app';

// Load React components that can be auto-loaded on DOMContentLoaded
import { ComponentLoader } from './component-loader';
import { ExampleComponent } from './components/example-component';
import {
  ActiveFundsTable,
  FundSeriesTable,
  FundPromisesTable,
  FundCapitalCallsTable,
  FundDividendsTable,
} from './components/table';
import { QuoteValueGraph } from './components/graph';
import { DashboardGraphSection } from './components/dashboard';

ComponentLoader.registerComponent('#react-example-component', ExampleComponent);
ComponentLoader.registerComponent('#react-active-funds-table', ActiveFundsTable);
ComponentLoader.registerComponent('#react-fund-series-table', FundSeriesTable);
ComponentLoader.registerComponent('#react-fund-promises-table', FundPromisesTable);
ComponentLoader.registerComponent('#react-fund-capital-calls-table', FundCapitalCallsTable);
ComponentLoader.registerComponent('#react-quote-value-graph', QuoteValueGraph);
ComponentLoader.registerComponent('#react-drashboard-graph', DashboardGraphSection);
ComponentLoader.registerComponent('#react-fund-dividends-table', FundDividendsTable);

/**
 * This runs on 'DOMContentLoaded', that means it waits for every javascript to be parsed and
 * executed and waits for stylesheets and defered external scripts. This may take a long time
 * if an external resource loads too slow (should we change this to <script defer src="...">?)
 */
window.addEventListener('DOMContentLoaded', () => {
  // Load the registered react components:
  ComponentLoader.start();

  setTimeout(() => {
    const mainAlerts = document.querySelectorAll('.main-alert .alert');
    mainAlerts.forEach((alert) => {
      window.bootstrap.Alert.getInstance(alert)?.close();
    });
  }, 10000);

  document.querySelectorAll('form')
    .forEach((form) => {
      form.addEventListener('submit', () => {
        const submitButtons = [...form.elements].filter((element) => (
          element.matches('[type="submit"]:not(.js-do-not-disable-on-submit)')
        ));

        // Disable buttons after submit to prevent disabling submit inputs
        // with values
        submitButtons.forEach((submitButton) => {
          // eslint-disable-next-line no-param-reassign
          (submitButton as HTMLButtonElement).disabled = true;
          App.Utils.showLoading(submitButton);
        });

        return true;
      });
    });

  // Init collapse
  document.querySelectorAll('.collapse')
    .forEach((collapse) => new Collapse(collapse, { toggle: false }));

  // Init alert
  document.querySelectorAll('.alert')
    .forEach((alert) => new Alert(alert));

  // Init Tooltip
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

  // Init dropdown
  const dropdownTriggerList = document.querySelectorAll('[data-bs-toggle="dropdown"]');
  [...dropdownTriggerList].map((dropdownTriggerEl) => new Dropdown(dropdownTriggerEl));
});
