import React from 'react';
import { JsonPropsType } from '../../component-loader';

export interface AsyncTablePaginationButtonProps {
  ariaLabel: string;
  disabled: boolean;
  icon: string;
  onClick: () => void;
}

export function AsyncTablePaginationButton({
  jsonObject,
}: JsonPropsType<AsyncTablePaginationButtonProps>) {
  if (!jsonObject) return <li />;

  const {
    ariaLabel,
    disabled,
    icon,
    onClick,
  } = jsonObject;
  return (
    <li className="flex-shrink-0">
      <button
        type="button"
        aria-label={ariaLabel}
        disabled={disabled}
        className="btn btn-table"
        onClick={onClick}
      >
        <span aria-hidden="true" className="icon material-icons-two-tone">
          {icon}
        </span>
      </button>
    </li>
  );
}
