import { eventDebounce } from '../utils/debounce';

class RutInput {
  input: HTMLInputElement;
  hasError: boolean;
  errorId: string;
  checkAvailability: ((v: string) => Promise<boolean | void>) | null | undefined;

  constructor(input: HTMLInputElement) {
    this.input = input;
    this.hasError = false;
    this.errorId = `${this.input.id}RutError`;
    this.checkAvailability = undefined;

    this.#createRutErrorElement();
    this.#setListeners();
  }

  /**
   * Add error element near input
   */
  #createRutErrorElement() {
    const error = `<span id="${this.errorId}" class="invalid-feedback">RUT inválido.</span>`;
    this.input.insertAdjacentHTML('afterend', error);
  }

  /**
   * Add event listeners
   */
  #setListeners() {
    const enableRutFormating = !this.input.classList.contains('js-do-not-format-rut');
    const enableRutValidation = !this.input.classList.contains('js-do-not-validate-rut');

    if (enableRutFormating) {
      this.input.addEventListener('keyup', this.formatRut.bind(this));
      this.input.addEventListener('change', this.formatRut.bind(this));
    }

    if (enableRutValidation) {
      this.input.addEventListener('keyup', eventDebounce(this.validateRut.bind(this)));
      this.input.addEventListener('change', this.validateRut.bind(this));
    }
  }

  /**
   * Set input value with correct rut format
   */
  formatRut() {
    let rut = this.input.value.replace(/-/g, '');

    if (rut !== '' && rut.length > 1) {
      rut = rut.replace(/\./g, '');

      const digits = rut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      const dv = rut.slice(-1);

      this.input.value = `${digits}-${dv}`;
    }
  }

  /**
   * Check if input value is a valid rut
   * @return {boolean}
   */
  rutIsValid() {
    const rut = this.input.value
      .replace(/-/g, '')
      .replace(/\./g, '');

    if (rut.length < 2) {
      return false;
    }

    const digits = rut.slice(0, -1);
    const dv = rut.slice(-1).toUpperCase();

    // calculate DV
    let sum = 0;
    let mult = 2;

    for (let i = digits.length - 1; i >= 0; i -= 1) {
      sum += parseInt(rut[i], 10) * mult;
      mult = (mult + 1) % 8 || 2;
    }

    // check DV
    switch (sum % 11) {
      case 1: return dv === 'K';
      case 0: return dv === '0';
      default: return `${11 - (sum % 11)}` === dv;
    }
  }

  /**
   * Show error messagge
   */
  showRutError(errorMessage = 'RUT inválido.') {
    const span = document.getElementById(this.errorId);
    if (span) span.innerHTML = errorMessage;

    if (this.hasError) return;

    const currentAriaDescribedby = this.input.getAttribute('aria-describedby') || '';
    const inputAriaDescribedby = `${this.errorId} ${currentAriaDescribedby}`
      .replace(/^\s+|\s+$/, '');

    this.input.classList.add('is-invalid');
    this.input.setAttribute('aria-describedby', inputAriaDescribedby);
    this.hasError = true;
  }

  /**
   * Hide error messagge
   */
  hideRutError() {
    if (!this.hasError) return;

    if (!this.input) {
      return;
    }

    const inputAriaDescribedby = this.input.getAttribute('aria-describedby')
      ?.replace(this.errorId, '')
      .replace(/^\s+|\s+$/, '');

    this.input.classList.remove('is-invalid');
    if (inputAriaDescribedby) {
      this.input.setAttribute('aria-describedby', inputAriaDescribedby);
    } else {
      this.input.removeAttribute('aria-describedby');
    }
    this.hasError = false;
  }

  defineRutAvailability(availabilityFunction: (value: string) => Promise<boolean>) {
    this.checkAvailability = availabilityFunction;
  }

  /**
   * Show error messagge if input value is not a correct rut
   */

  validateRut() {
    if (!this.rutIsValid()) this.showRutError();
    else if (!this.checkAvailability) this.hideRutError();
    else {
      this.checkAvailability(this.input.value)?.then((checkAvailability) => {
        if (checkAvailability) this.hideRutError();
        else this.showRutError('RUT ya registrado.');
      }).catch(() => {
        this.showRutError('Error al validar RUT.');
      });
    }
  }
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  const rutInput = document.querySelectorAll('.client-rut-input');
  rutInput.forEach((input) => {
    // eslint-disable-next-line no-new
    const rutInputInstance = new RutInput(input as HTMLInputElement);

    rutInputInstance.defineRutAvailability(
      (value) => {
        let url = `/api/v1/intranet/clients/rut-check/?rut=${value}`;
        const clientId = document.getElementById('clientId')?.innerHTML;
        if (clientId) url += `&client=${clientId}`;
        return fetch(url).then(async (res) => await res.json() as boolean);
      });
  });
});
