import React, { useEffect, useState } from 'react';
import { JsonPropsType } from '../../component-loader';

type SortingStates = 'asc' | 'desc' | 'none';

export interface AsyncTableHeadProps {
  sortLabel?: string;
  text: string;
  onSort?: (param: string) => void;
  isSorting?: boolean;
  classes?: string;
}

export function AsyncTableHead(props: JsonPropsType<AsyncTableHeadProps>) {
  const [sortingState, setSortingState] = useState<SortingStates>('none');
  const [sortParam, setSortParam] = useState(props.jsonObject?.sortLabel || '');

  useEffect(() => {
    if (sortParam.startsWith('-')) {
      setSortingState('asc');
    } else {
      setSortingState('desc');
    }
    if (props.jsonObject?.onSort) {
      props.jsonObject.onSort(sortParam);
    }
  }, [sortParam]);

  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  if (!props.jsonObject) return <th />;

  const {
    text,
    sortLabel,
    onSort,
    isSorting,
    classes,
  } = props.jsonObject;

  const handleSort = () => {
    if (!sortLabel || !onSort) return;
    setSortParam((prev) => (prev.startsWith('-') ? prev.slice(1) : `-${sortLabel}`));
  };

  const sortStateClassNameMap: Record<SortingStates, string> = {
    asc: 'table-header-sorting-asc',
    desc: 'table-header-sorting-desc',
    none: '',
  };

  const currentSortStateClass = isSorting ? sortStateClassNameMap[sortingState] : '';

  return (
    <th
      key={sortParam}
      className={`${classes || ''} ${sortParam ? `table-header-sorting ${currentSortStateClass}` : ''}`}
    >
      {text}
      {sortLabel && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          aria-label={`Sort by ${text}`}
          href={`o=${sortLabel}`}
          onClick={(e) => {
            e.preventDefault();
            handleSort();
          }}
        />
      )}
    </th>
  );
}
