import Choices from 'choices.js';
import { FundSeries } from '../types/funds';
import { getChoicesReferenceById } from '../vendors/choices';

const getFundSelect = () => document.querySelector<HTMLSelectElement>('#id_fund');

const getSeriesChoicesRef = (): Choices | undefined => {
  const select = document.querySelector<HTMLSelectElement>('#id_fund_series');
  if (!select) return undefined;

  const choicesRef = getChoicesReferenceById('id_fund_series');
  return choicesRef?.choicesRef || undefined;
};

const handleFormLoading = ({ show }: { show: boolean }) => {
  const certificateForm = document.querySelector<HTMLFormElement>('#investorCertificateForm');
  const fundSelect = getFundSelect();
  const seriesChoicesRef = getSeriesChoicesRef();

  if (!certificateForm || !fundSelect || !seriesChoicesRef) return;

  const fundSelectChoicesRef = getChoicesReferenceById(fundSelect.id);
  if (!fundSelectChoicesRef || !fundSelectChoicesRef.choicesRef) return;

  const submitButton = certificateForm.querySelector<HTMLButtonElement>('button');
  if (!submitButton) return;

  submitButton.disabled = show;
  if (show) {
    fundSelectChoicesRef.choicesRef.disable();
    seriesChoicesRef.disable();
  } else {
    fundSelectChoicesRef.choicesRef.enable();
    seriesChoicesRef.enable();
  }
};

const handleErrorFeedback = ({ show }: { show: boolean }) => {
  const certificateForm = document.querySelector<HTMLFormElement>('#investorCertificateForm');
  if (!certificateForm) return;

  const feedbackContainerId = 'certificate_error_feedback';
  const feedbackContainer = certificateForm.querySelector(`#${feedbackContainerId}`);

  if (feedbackContainer) feedbackContainer.remove();

  if (show) {
    const newContainer = document.createElement('p');
    newContainer.classList.add('text-danger');
    newContainer.textContent = 'Ocurrió un error. Por favor inténtelo nuevamente';
    newContainer.id = feedbackContainerId;
    certificateForm.appendChild(newContainer);
  }
};

const populateSeriesSelect = async (data: FundSeries[]) => {
  const select = document.querySelector<HTMLSelectElement>('#id_fund_series');
  if (!select) return;

  const choicesRef = getSeriesChoicesRef();
  if (choicesRef) {
    const selectOptions = [{ value: '', label: '---------' }];
    selectOptions.push(...data.map((entry) => ({ value: entry.id.toString(), label: entry.name })));
    await choicesRef.setChoices(selectOptions, 'value', 'label', true);
    choicesRef.setChoiceByValue('');
  }
};

const fetchFundSeries = async (fundId: string) => {
  try {
    const res = await fetch(`/api/v1/investors/fund-series/?no_page=true&fund_id=${fundId}`);
    const data = await res.json() as FundSeries[];
    return data;
  } catch (error) {
    throw new Error('Error fetching series');
  }
};

const handleFundSelectChange = (e: Event) => {
  const select = e.target as HTMLSelectElement;
  handleFormLoading({ show: true });
  handleErrorFeedback({ show: false });
  fetchFundSeries(select.value)
    .then((data: FundSeries[]) => {
      populateSeriesSelect(data)
        .catch(() => { throw new Error('Error populating select'); });
    })
    .catch((err) => {
      console.error(err);
      handleErrorFeedback({ show: true });
    })
    .finally(() => {
      handleFormLoading({ show: false });
    });
};

const handleCertificateForm = () => {
  const fundSelect = getFundSelect();
  if (!fundSelect) return;

  fundSelect.addEventListener('change', handleFundSelectChange);

  const seriesChoicesRef = getSeriesChoicesRef();
  if (seriesChoicesRef) {
    seriesChoicesRef.clearChoices();
  }
};

window.addEventListener('DOMContentLoaded', () => {
  const certificateForm = document.querySelector<HTMLFormElement>('#investorCertificateForm');
  if (!certificateForm) return;

  handleCertificateForm();
});
